import * as React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout isSinglePage={true} title={siteTitle}>
        <Seo title="Alle Blogbeiträge" />
        <p>Keine Blogeinträge gefunden.</p>
      </Layout>
    )
  }

  return (
    <Layout isSinglePage={true} title={siteTitle}>
      <Seo title="Alle Blogbeiträge" />

      <section className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug} className="border-b pb-4">
                <Link
                  to={`/blog${post.fields.slug}`}
                  itemProp="url"
                  className="post-list-item"
                >
                  <article
                    itemScope
                    itemType="http://schema.org/Article"
                    className="hover:"
                  >
                    <header>
                      <h2 itemProp="headline">{title}</h2>
                      <small>
                        {post.frontmatter.date},{" "}
                        {Math.round(post.fields.readingTime.minutes) > 0
                          ? Math.round(post.fields.readingTime.minutes)
                          : "1"}
                        {Math.round(post.fields.readingTime.minutes) < 2
                          ? " Minute"
                          : " Minuten"}{" "}
                        Lesezeit
                      </small>
                    </header>
                    <div>
                      <Img
                        fluid={
                          post.frontmatter.featuredImage.childImageSharp.fluid
                        }
                        className="shadow-lg my-4"
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </div>
                  </article>
                </Link>
              </li>
            )
          })}
        </ol>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            minutes
          }
        }
        frontmatter {
          date(formatString: "DD. MMMM YYYY", locale: "de")
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

BlogIndex.propTypes = {
  data: PropTypes.object,
}
